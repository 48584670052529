export const githubCommits = {
    "2022-06-20": 0,
    "2022-06-21": 0,
    "2022-06-22": 0,
    "2022-06-23": 4,
    "2022-06-24": 5,
    "2022-06-25": 6,
    "2022-06-26": 0,
    "2022-06-27": 0,
    "2022-06-28": 0,
    "2022-06-29": 4,
    "2022-06-30": 0,
    "2022-07-01": 1,
    "2022-07-02": 2,
    "2022-07-03": 7,
    "2022-07-04": 0,
    "2022-07-05": 0,
    "2022-07-06": 0,
    "2022-07-07": 0,
    "2022-07-08": 0,
    "2022-07-09": 0,
    "2022-07-10": 0,
    "2022-07-11": 0,
    "2022-07-12": 0,
    "2022-07-13": 0,
    "2022-07-14": 0,
    "2022-07-15": 0,
    "2022-07-16": 0,
    "2022-07-17": 0,
    "2022-07-18": 0,
    "2022-07-19": 0,
    "2022-07-20": 0,
    "2022-07-21": 0,
    "2022-07-22": 0,
    "2022-07-23": 0,
    "2022-07-24": 2,
    "2022-07-25": 7,
    "2022-07-26": 5,
    "2022-07-27": 0,
    "2022-07-28": 0,
    "2022-07-29": 0,
    "2022-07-30": 7,
    "2022-07-31": 0,
    "2022-08-01": 0,
    "2022-08-02": 0,
    "2022-08-03": 0,
    "2022-08-04": 5,
    "2022-08-05": 4,
    "2022-08-06": 0,
    "2022-08-07": 2,
    "2022-08-08": 1,
    "2022-08-09": 3,
    "2022-08-10": 13,
    "2022-08-11": 4,
    "2022-08-12": 4,
    "2022-08-13": 2,
    "2022-08-14": 3,
    "2022-08-15": 3,
    "2022-08-16": 5,
    "2022-08-17": 2,
    "2022-08-18": 1,
    "2022-08-19": 1,
    "2022-08-20": 1,
    "2022-08-21": 0,
    "2022-08-22": 3,
    "2022-08-23": 0,
    "2022-08-24": 8,
    "2022-08-25": 1,
    "2022-08-26": 1,
    "2022-08-27": 0,
    "2022-08-28": 3,
    "2022-08-29": 4,
    "2022-08-30": 6,
    "2022-08-31": 7,
    "2022-09-01": 1,
    "2022-09-02": 1,
    "2022-09-03": 1,
    "2022-09-04": 1,
    "2022-09-05": 1,
    "2022-09-06": 1,
    "2022-09-07": 1,
    "2022-09-08": 1,
    "2022-09-09": 1,
    "2022-09-10": 4,
    "2022-09-11": 3,
    "2022-09-12": 4,
    "2022-09-13": 3,
    "2022-09-14": 5,
    "2022-09-15": 0,
    "2022-09-16": 0,
    "2022-09-17": 6,
    "2022-09-18": 0,
    "2022-09-19": 0,
    "2022-09-20": 5,
    "2022-09-21": 4,
    "2022-09-22": 4,
    "2022-09-23": 4,
    "2022-09-24": 0,
    "2022-09-25": 0,
    "2022-09-26": 4,
    "2022-09-27": 4,
    "2022-09-28": 5,
    "2022-09-29": 7,
    "2022-09-30": 3,
    "2022-10-01": 2,
    "2022-10-02": 0,
    "2022-10-03": 1,
    "2022-10-04": 4,
    "2022-10-05": 5,
    "2022-10-06": 8,
    "2022-10-07": 6,
    "2022-10-08": 8,
    "2022-10-09": 6,
    "2022-10-10": 3,
    "2022-10-11": 3,
    "2022-10-12": 4,
    "2022-10-13": 5,
    "2022-10-14": 0,
    "2022-10-15": 0,
    "2022-10-16": 0,
    "2022-10-17": 0,
    "2022-10-18": 7,
    "2022-10-19": 21,
    "2022-10-20": 13,
    "2022-10-21": 16,
    "2022-10-22": 5,
    "2022-10-23": 11,
    "2022-10-24": 4,
    "2022-10-25": 6,
    "2022-10-26": 5,
    "2022-10-27": 3,
    "2022-10-28": 11,
    "2022-10-29": 8,
    "2022-10-30": 0,
    "2022-10-31": 6,
    "2022-11-01": 10,
    "2022-11-02": 1,
    "2022-11-03": 0,
    "2022-11-04": 5,
    "2022-11-05": 0,
    "2022-11-06": 0,
    "2022-11-07": 1,
    "2022-11-08": 11,
    "2022-11-09": 7,
    "2022-11-10": 1,
    "2022-11-11": 0,
    "2022-11-12": 0,
    "2022-11-13": 0,
    "2022-11-14": 0,
    "2022-11-15": 4,
    "2022-11-16": 17,
    "2022-11-17": 24,
    "2022-11-18": 8,
    "2022-11-19": 23,
    "2022-11-20": 9,
    "2022-11-21": 9,
    "2022-11-22": 3,
    "2022-11-23": 0,
    "2022-11-24": 6,
    "2022-11-25": 10,
    "2022-11-26": 5,
    "2022-11-27": 2,
    "2022-11-28": 7,
    "2022-11-29": 18,
    "2022-11-30": 12,
    "2022-12-01": 3,
    "2022-12-02": 6,
    "2022-12-03": 0,
    "2022-12-04": 4,
    "2022-12-05": 3,
    "2022-12-06": 11,
    "2022-12-07": 9,
    "2022-12-08": 7,
    "2022-12-09": 5,
    "2022-12-10": 5,
    "2022-12-11": 1,
    "2022-12-12": 3,
    "2022-12-13": 2,
    "2022-12-14": 10,
    "2022-12-15": 5,
    "2022-12-16": 18,
    "2022-12-17": 7,
    "2022-12-18": 4,
    "2022-12-19": 0,
    "2022-12-20": 38,
    "2022-12-21": 8,
    "2022-12-22": 7,
    "2022-12-23": 9,
    "2022-12-24": 13,
    "2022-12-25": 7,
    "2022-12-26": 0,
    "2022-12-27": 2,
    "2022-12-28": 0,
    "2022-12-29": 6,
    "2022-12-30": 9,
    "2022-12-31": 3,
    "2023-01-01": 9,
    "2023-01-02": 12,
    "2023-01-03": 4,
    "2023-01-04": 9,
    "2023-01-05": 10,
    "2023-01-06": 0,
    "2023-01-07": 0,
    "2023-01-08": 3,
    "2023-01-09": 0,
    "2023-01-10": 2,
    "2023-01-11": 6,
    "2023-01-12": 9,
    "2023-01-13": 14,
    "2023-01-14": 0,
    "2023-01-15": 0,
    "2023-01-16": 2,
    "2023-01-17": 0,
    "2023-01-18": 1,
    "2023-01-19": 10,
    "2023-01-20": 9,
    "2023-01-21": 10,
    "2023-01-22": 0,
    "2023-01-23": 3,
    "2023-01-24": 5,
    "2023-01-25": 1,
    "2023-01-26": 1,
    "2023-01-27": 7,
    "2023-01-28": 3,
    "2023-01-29": 8,
    "2023-01-30": 10,
    "2023-01-31": 6,
    "2023-02-01": 14,
    "2023-02-02": 10,
    "2023-02-03": 16,
    "2023-02-04": 8,
    "2023-02-05": 0,
    "2023-02-06": 6,
    "2023-02-07": 3,
    "2023-02-08": 14,
    "2023-02-09": 9,
    "2023-02-10": 7,
    "2023-02-11": 12,
    "2023-02-12": 2,
    "2023-02-13": 5,
    "2023-02-14": 1,
    "2023-02-15": 0,
    "2023-02-16": 0,
    "2023-02-17": 1,
    "2023-02-18": 3,
    "2023-02-19": 0,
    "2023-02-20": 1,
    "2023-02-21": 7,
    "2023-02-22": 2,
    "2023-02-23": 2,
    "2023-02-24": 0,
    "2023-02-25": 0,
    "2023-02-26": 1,
    "2023-02-27": 7,
    "2023-02-28": 3,
    "2023-03-01": 0,
    "2023-03-02": 0,
    "2023-03-03": 0,
    "2023-03-04": 1,
    "2023-03-05": 0,
    "2023-03-06": 0,
    "2023-03-07": 0,
    "2023-03-08": 0,
    "2023-03-09": 15,
    "2023-03-10": 7,
    "2023-03-11": 4,
    "2023-03-12": 8,
    "2023-03-13": 4,
    "2023-03-14": 1,
    "2023-03-15": 0,
    "2023-03-16": 0,
    "2023-03-17": 5,
    "2023-03-18": 12,
    "2023-03-19": 10,
    "2023-03-20": 7,
    "2023-03-21": 11,
    "2023-03-22": 6,
    "2023-03-23": 1,
    "2023-03-24": 4,
    "2023-03-25": 12,
    "2023-03-26": 4,
    "2023-03-27": 0,
    "2023-03-28": 13,
    "2023-03-29": 2,
    "2023-03-30": 7,
    "2023-03-31": 9,
    "2023-04-01": 3,
    "2023-04-02": 0,
    "2023-04-03": 2,
    "2023-04-04": 3,
    "2023-04-05": 9,
    "2023-04-06": 5,
    "2023-04-07": 12,
    "2023-04-08": 0,
    "2023-04-09": 0,
    "2023-04-10": 1,
    "2023-04-11": 0,
    "2023-04-12": 0,
    "2023-04-13": 5,
    "2023-04-14": 6,
    "2023-04-15": 0,
    "2023-04-16": 0,
    "2023-04-17": 10,
    "2023-04-18": 0,
    "2023-04-19": 0,
    "2023-04-20": 0,
    "2023-04-21": 0,
    "2023-04-22": 0,
    "2023-04-23": 0,
    "2023-04-24": 0,
    "2023-04-25": 0,
    "2023-04-26": 2,
    "2023-04-27": 5,
    "2023-04-28": 10,
    "2023-04-29": 7,
    "2023-04-30": 0,
    "2023-05-01": 7,
    "2023-05-02": 15,
};
